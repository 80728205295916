<template>
    <TheLayout class="layout--with-sidebar">
        <aside class="layout-side">
            <AppLayoutInner>
                <AppHeader>
                    <AppTitle v-translate>
                        Account
                    </AppTitle>
                </AppHeader>
            </AppLayoutInner>
        </aside>
        <AppLayoutMain>
            <AppPage>
                <AppWrapper variant="normal">
                    <AppForm
                        ref="form"
                        lazy-validation
                        @submit.prevent="formSubmit"
                    >
                        <AppPageHeader>
                            <div class="page-header-content">
                                <p
                                    v-if="account.modified"
                                    v-translate="{
                                        modified: $moment(
                                            account.modified
                                        ).format('LL')
                                    }"
                                    class="t-size-small"
                                >
                                    <strong>Laatst bewerkt:</strong> %{modified}
                                </p>

                                <AppButton
                                    class="page-header__button"
                                    variant="secondary medium-with-icon"
                                    :disabled="!onLine"
                                    icon="check-small"
                                    :loading="loading > 0"
                                    :title="$gettext('Opslaan')"
                                    type="submit"
                                >
                                    <translate>Opslaan</translate>
                                </AppButton>
                            </div>
                        </AppPageHeader>

                        <AppContent
                            class="u-margin-bottom"
                            variant="rounded background"
                        >
                            <AppHeader>
                                <AppTitle
                                    v-translate
                                    as="h2"
                                    class="subtitle header__subtitle"
                                >
                                    Persoonsgegevens
                                </AppTitle>
                            </AppHeader>

                            <AppDetailList>
                                <dt v-translate class="detail-list__label">
                                    Voornaam
                                </dt>
                                <dd class="detail-list__value">
                                    {{ account.firstName }}
                                </dd>
                                <dt v-translate class="detail-list__label">
                                    Achternaam
                                </dt>
                                <dd class="detail-list__value">
                                    {{ account.lastName }}
                                </dd>
                                <dt v-translate class="detail-list__label">
                                    E-mailadres
                                </dt>
                                <dd class="detail-list__value">
                                    {{ account.email }}
                                </dd>
                            </AppDetailList>
                        </AppContent>

                        <AppSection>
                            <AppHeader>
                                <AppTitle v-translate as="h2" class="subtitle">
                                    Wachtwoord wijzigen
                                </AppTitle>
                            </AppHeader>

                            <AppRow variant="inner-gutters">
                                <AppColumn>
                                    <AppFormGroup>
                                        <AppInputText
                                            v-model="formValues.passwordOld"
                                            autocomplete="current-password"
                                            :disabled="loading > 0"
                                            :error-messages="
                                                formErrors.passwordOld
                                            "
                                            :label="
                                                $gettext('Huidig wachtwoord')
                                            "
                                            required
                                            type="password"
                                        />
                                    </AppFormGroup>
                                </AppColumn>

                                <AppColumn>
                                    <AppInputText
                                        v-model="formValues.passwordNew"
                                        autocomplete="new-password"
                                        :disabled="loading > 0"
                                        :error-messages="formErrors.passwordNew"
                                        :label="$gettext('Nieuw wachtwoord')"
                                        required
                                        type="password"
                                    />
                                </AppColumn>
                            </AppRow>
                        </AppSection>
                    </AppForm>
                </AppWrapper>
            </AppPage>
        </AppLayoutMain>
    </TheLayout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import AppButton from "@/components/AppButton";
import AppColumn from "@/components/AppColumn";
import AppContent from "@/components/AppContent";
import AppDetailList from "@/components/AppDetailList.vue";
import AppForm from "@/components/AppForm";
import AppFormGroup from "@/components/AppFormGroup.vue";
import AppHeader from "@/components/AppHeader";
import AppInputText from "@/components/AppInputText";
import AppLayoutInner from "@/components/AppLayoutInner";
import AppLayoutMain from "@/components/AppLayoutMain";
import AppPage from "@/components/AppPage";
import AppPageHeader from "@/components/AppPageHeader.vue";
import AppRow from "@/components/AppRow";
import AppSection from "@/components/AppSection";
import AppTitle from "@/components/AppTitle.vue";
import AppWrapper from "@/components/AppWrapper.vue";
import FormSave from "@/mixins/FormSave";
import TheLayout from "@/components/TheLayoutDefault";
import gql from "graphql-tag";

export default {
    components: {
        AppFormGroup,
        AppDetailList,
        AppPageHeader,
        AppWrapper,
        AppTitle,
        AppPage,
        AppLayoutInner,
        AppLayoutMain,
        AppRow,
        AppColumn,
        AppSection,
        AppHeader,
        AppContent,
        AppInputText,
        AppButton,
        AppForm,
        TheLayout
    },
    mixins: [FormSave],
    data() {
        return {
            account: {},
            formValues: {
                passwordOld: "",
                passwordNew: ""
            },
            emptyFormErrors: {
                passwordOld: [],
                passwordNew: []
            }
        };
    },
    computed: mapGetters("online", ["onLine"]),
    apollo: {
        account: {
            query: gql`
                query meAccount {
                    me {
                        id
                        firstName
                        lastName
                        email
                        modified
                    }
                }
            `,
            update(data) {
                if (data.me) {
                    const me = { ...data.me };
                    this.setFormValues(me);
                    return me;
                } else {
                    return this.account;
                }
            }
        }
    },
    methods: {
        ...mapActions("message", ["showMessage"]),
        getMutation() {
            const input = {
                passwordOld: this.formValues.passwordOld,
                passwordNew: this.formValues.passwordNew
            };

            return {
                mutation: gql`
                    mutation account($input: AccountMutationInput!) {
                        account(input: $input) {
                            teacher {
                                id
                                firstName
                                lastName
                                email
                                modified
                            }
                            errors {
                                field
                                messages
                            }
                        }
                    }
                `,
                variables: {
                    input: input
                },
                fetchPolicy: "no-cache"
            };
        },
        formSuccess() {
            this.$refs.form.reset();
            this.showMessage({
                message: this.$gettext(
                    "Uw gegevens zijn succesvol opgeslagen."
                ),
                type: "success"
            });
        }
    }
};
</script>
